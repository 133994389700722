<template>
  <div>
    <ayl-berad-nav :nav="nav"/>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table">
          <div slot="ctrl-button">
            <div class="export-btn ml15px" @click="clickExport">导出</div>
          </div>
        </ayl-table>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import mixins from '../../../utils/mixins'

  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        nav: [{name: '油耗管理', path: ''}, {name: '油量骤减', path: ''}],
        dialogExport: false,
        btnLoading: false,
        checkList: [],
        checkIndex: [],
        table: {
          api: vm.$api.listOilEventInfo,
          query: {
            eventType: '油量骤减',
            carBrand: null,
            startTime: null,
            endTime: null,
            queryContent: null,
          },
          searchData: [{
            type: 'select',
            title: '品牌型号',
            model: 'carBrand',
            placeholder: '请选择',
            option: [],
          }, {
            type: 'dateSection',
            title: '时间设置',
            // 是否需要快捷选项
            isOptions: true,
            model: [],
            modelName: ['startTime', 'endTime'],
          }, {
            type: 'input',
            model: 'queryContent',
            placeholder: '车牌号/设备号',
            maxlength: '12'
          }],
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
            title: '驾驶员',
            key: 'driverName',
          }, {
            title: '车牌号',
            key: 'plateNumber',
          }, {
            title: '品牌型号',
            key: 'carBrand',
          }, {
            title: '设备号',
            key: 'deviceName',
          }, {
            title: '油量骤减开始时间',
            key: 'startTime',
            filter: 'str2ymdhm',
            width: '150px'
          }, {
            title: '油量骤减结束时间',
            key: 'endTime',
            filter: 'str2ymdhm',
            width: '150px'
          }, {
            title: '油量骤减量（L）',
            key: 'differenceOilMass',
            sortable: 'custom',
            width: '170px'
          }, {
            title: '车辆位置',
            key: 'location',
          }, {
            title: '操作',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.details.bind(this, ctx.row)
                  }
                }, '查看详情')
              ])
            }
          }]
        }
      }
    },
    methods: {
      clickExport () {
        this.dialogExport = true
        // 导出
        this.$api.GetExcelExportKeyAndColumn({
          excelKey: 'listOilEventInfoOfReduce'
        }).then((res) => {
          this.checkList = res.chsList
          this.checkIndex = this.checkList.map(e => {
            return e.index
          })
        })
      },
      //查看
      details (val) {
        this.$router.push({
          path: '/oil-drop/oil-drop/details',
          query: {
            plateNumber: val.plateNumber,
            startTime: val.startTime
          }
        })
      },
      //导出
      async exportExcel () {
        this.dialogExport = true
        try {
          const url = await this.$api.ListOilOverviewExcel({
            params: {
              eventType: this.table.query.eventType,
              queryContent: this.table.query.queryContent,
              carBrand: this.table.query.carBrand,
              startTime: this.table.query.startTime,
              endTime: this.table.query.endTime,
              orderMode: this.table.query.orderMode,
              orderRule: this.table.query.orderRule,
            },
            excelKey: 'listOilEventInfoOfReduce',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        } catch (e) {
          console.log(e)
        }
        this.btnLoading = false
      },
    },
    async mounted () {
      const carBrand = await this.$api.getListCarBrand({})
      this.table.searchData[0].option = carBrand.map(e => {
        return {
          value: e,
          label: e,
        }
      })
      await this.$search(this.table)
    }
  }
</script>
<style lang='sass' scoped>
  /deep/ .el-checkbox
    width: 120px
</style>
